import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';

interface TimeRangeSelectorProps {
  onChange: (startTime: string, endTime: string) => void;
  startTime: string;
  endTime: string;
  setIsVaild: (isVaild: boolean) => void;
  isVaild: boolean;
}

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  onChange,
  startTime,
  endTime,
  setIsVaild,
  isVaild,
}) => {
  const [selectedStartTime, setSelectedStartTime] = useState<string>(startTime);
  const [selectedEndTime, setSelectedEndTime] = useState<string>(endTime);

  const options = Array.from({ length: 96 }, (_, i) => {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;
    const value = moment({ hour, minute }).format('HH:mm:ss');
    return { label: value, value };
  });

  const handleStartTimeChange = (option: any) => {
    const value = option.value;
    setSelectedStartTime(value);
    onChange(value, selectedEndTime);
    timeCheck(value, selectedEndTime);
  };

  const handleEndTimeChange = (option: any) => {
    const value = option.value;
    setSelectedEndTime(value);
    onChange(selectedStartTime, value);
    timeCheck(selectedStartTime, value);
  };

  const timeCheck = (start: string, end: string) => {
    const s_Time = moment(start, 'HH:mm:ss');
    const e_Time = moment(end, 'HH:mm:ss');
    setIsVaild(s_Time.isAfter(e_Time));
  };

  useEffect(() => {
    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);
  }, [startTime, endTime]);

  const customStyles = {
    control: (provided:any) => ({
      ...provided,
      border: '1px solid #E5E7EB', // Change this to your desired border color and width
      boxShadow: 'none', // Remove default shadow
      borderRadius: '12px',
      padding:'3.3px 8px',
      '&:hover': {
        border: '1px solid #E5E7EB', // Change the border on hover
      },
    }),
  };
  return (
    <div className="flex flex-col space-y-4 mt-1">
      <div className="flex items-baseline space-x-4">
        <div className="flex w-[50%] flex-col">
          <Select
            options={options}
            // value={options.find(option => option.value === selectedStartTime)}
            onChange={handleStartTimeChange}
            classNamePrefix="react-select"
            styles={customStyles}
            isSearchable={false}
          />
          {isVaild && (
            <div role="alert" className="mt-0.5 text-[13px] text-red-500">
              {'Invalid time selection! End time must be after start time.'}
            </div>
          )}
        </div>
        <div className="flex w-[50%] flex-col">
          <Select
            options={options}
            onChange={handleEndTimeChange}
            classNamePrefix="react-select"
            styles={customStyles}
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSelector;
