import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface FounderSectionProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const FounderSection: FC<FounderSectionProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row-reverse lg:gap-10 space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <h4 className="text-2xl !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-3xl dark:text-neutral-100">
            Travon Humphries
          </h4>
          <span className="text-lg !leading-tight font-semibold md:text-lg xl:text-lg text-neutral-6000 dark:text-neutral-400">
            Lead management specialist
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-justify">
            Serial entrepreneur with a passion for customer service and cars.
            Graduated from Florida Agricultural and Mechanical University with a
            bachelor's degree in interdisciplinary studies. Experienced in
            owning and managing investment and multi-level marketing companies,
            and currently serving as the president of a rental management
            company in North Florida.
          </span>
          {!!btnText && <ButtonPrimary href="/signup">{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow" style={{marginLeft:"0px"}}>
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FounderSection;
