import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import FounderSection from "containers/PageAbout/FounderSection";
import SectionHero2 from "containers/PageAbout/SectionHero2";
import { globalJson } from "global/global_json";
import founder from "images/founder.jpeg";
import rightImg from "images/image.jpeg";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden mt-4">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 ">
        <SectionHero3 className="" />
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>

      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <SectionHero2
            rightImg={rightImg}
            heading="About Us."
            btnText=""
            subHeading={globalJson?.about_us_para}
          />

          {/* <SectionFounder/> */}
          <div>{/* <Heading>Our clients say</Heading> */}</div>
        </div>
        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <FounderSection
            rightImg={founder}
            heading="Founder"
            btnText=""
            subHeading={globalJson?.about_us_para}
          />
        </div>
        <div className="relative py-16">
          <BackgroundSection className="w-full" />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
      </div>

      <div className="container">
        <SectionSubscribe2 />
      </div>
    </div>
  );
}

export default PageHome3;
