import sideImage from "images/side-image.png";
import imagePng from "images/travelhero2.png";

import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

import moment from "moment";
import toast from "react-hot-toast";
import DatePickers from "./DatePicker";
import TimePicker from "./TimePicker";
import { sendRentalTerms } from "api/auth/auth";
export interface PlacesRentalProps {
  className?: string;
}

const PlacesRental: FC<PlacesRentalProps> = ({ className = "" }) => {
  const initialData = {
    startDate: null,
    endDate: null,
    location: "",
    name: "",
    email: "",
    phone_no: "",
    message: "",
  };
  const [getData, setData] = useState<any>(initialData);
  const [getLoading, setLoading] = useState<any>(false);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    setData({
      ...getData,
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const [startTime, setStartTime] = useState<string>("00:00:00");
  const [endTime, setEndTime] = useState<string>("00:00:00");
  const [isVaild, setIsVaild] = useState<boolean>(false);

  const handleTimeRangeChange = (newStartTime: string, newEndTime: string) => {
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  function validateData(obj: any) {
    const emptyKeys = [];
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        emptyKeys.push(key);
      }
    }
    return emptyKeys;
  }

  function validateEmail(email: string) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const handelSubmit = async () => {
    let data = {
      ...getData,
      startTime: startTime,
      endTime: endTime,
      startDate: getData?.startDate
        ? moment(getData?.startDate).format("DD/MM/YYYY")
        : null,
      endDate: getData?.endDate
        ? moment(getData?.endDate).format("DD/MM/YYYY")
        : null,
      flag: "Places Rental",
    };
    const emptyFields = validateData(data);
    if (emptyFields.length > 0) {
      toast.error(`The following fields should not be empty!`);
      return 0;
    } else {
      if (!validateEmail(data?.email)) {
        toast.error(`Invalid email address!`);
        return 0;
      }
    }
    setLoading(true);
    try {
      const response = await sendRentalTerms(data);
      if (response) {
        // Reset form state
        setData(initialData);
        setStartTime("00:00:00");
        setEndTime("00:00:00");
        toast.success("Your request is successfully submitted!");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching rental agreement content:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Places Rental</title>
      </Helmet>
      <div
        className={`nc-SectionHero3 relative ${className}`}
        data-nc-id="SectionHero3"
      >
        <div className="absolute z-10 inset-x-0 sm:top-10 top-5 text-center flex flex-col items-center mx-auto ">
          <h2 className="font-bold text-secondary-900 text-3xl sm:text-4xl md:text-5xl lg:text-7xl md:w-[55%]">
            Looking to rent a <span className="text-primary-900">place?</span>
          </h2>

          <div className="z-10 w-full text-left sm:px-36 px-3">
            <div
              className="relative rounded-lg sm:p-12 p-8 mt-6 mx-4 shadow-xl bg-white"
              style={{ borderRadius: "50px" }}
            >
              <form>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 gap-4">
                  <div>
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Place Location
                    </h3>
                    <Input
                      className="mt-1 w-full"
                      placeholder="Enter Place"
                      value={getData?.location}
                      onChange={(e) => {
                        setData({
                          ...getData,
                          location: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Date
                    </h3>
                    <DatePickers
                      onChangeDate={onChangeDate}
                      getDate={getData}
                    />
                  </div>
                  <div className="lg:col-span-1 col-span-full">
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Time
                    </h3>
                    <TimePicker
                      onChange={handleTimeRangeChange}
                      startTime={startTime}
                      endTime={endTime}
                      setIsVaild={setIsVaild}
                      isVaild={isVaild}
                    />
                  </div>
                  <div>
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Full Name
                    </h3>
                    <Input
                      className="mt-1 w-full"
                      placeholder="Joe Shmoe"
                      value={getData?.name}
                      onChange={(e) => {
                        setData({
                          ...getData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Email Address
                    </h3>
                    <Input
                      className="mt-1 w-full"
                      placeholder="JoeShmoe@example.com"
                      value={getData?.email}
                      onChange={(e) => {
                        setData({
                          ...getData,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Phone Number
                    </h3>
                    <Input
                      className="mt-1 w-full"
                      placeholder="+1 456 789 1230"
                      value={getData?.phone_no}
                      type="number"
                      onChange={(e) => {
                        setData({
                          ...getData,
                          phone_no: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-span-full">
                    <h3 className="font-bold text-secondary-900 text-xl">
                      Message/ Purpose of state
                    </h3>
                    <Textarea
                      className="mt-1"
                      rows={3}
                      value={getData?.message}
                      onChange={(e) => {
                        setData({
                          ...getData,
                          message: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-span-full text-end">
                    <ButtonPrimary
                      fontSize="text-sm sm:text-base lg:text-lg font-medium"
                      type="button"
                      onClick={() => {
                        handelSubmit();
                      }}
                      loading={getLoading}
                    >
                      Send Request
                    </ButtonPrimary>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="relative aspect-w-4 aspect-h-10 sm:aspect-h-16 sm:aspect-w-10 md:aspect-w-16 xl:aspect-h-10">
          <img
            className="absolute inset-0 object-cover rounded-xl opacity-50"
            src={imagePng}
            alt="hero"
          />
        </div>
        <div className="bg-white flex md:flex-row flex-col md:p-24 p-6">
          <div className="basis-full">
            <h2 className="font-bold text-secondary-900 text-3xl">
              Looking to rent a place?
            </h2>
            <p className="my-3">
              Looking for a flexible and convenient way to meet your space
              needs? Our long-term place rental options provide the perfect
              solution for extended stays, business trips, or simply enjoying
              the freedom of having your own place without the commitment of
              ownership.
            </p>
            <p className="my-2 flex">
              <div>
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 21.5928C16.5228 21.5928 21 17.1156 21 11.5928C21 6.06993 16.5228 1.59277 11 1.59277C5.47715 1.59277 1 6.06993 1 11.5928C1 17.1156 5.47715 21.5928 11 21.5928Z"
                    stroke="#53B648"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M7.5 12.0928L9.5 14.0928L14.5 9.09277"
                    stroke="#53B648"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="ms-3">
                Save money with competitive rates tailored for extended rentals.
              </span>
            </p>
            <p className="my-2 flex">
              <div>
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 21.5928C16.5228 21.5928 21 17.1156 21 11.5928C21 6.06993 16.5228 1.59277 11 1.59277C5.47715 1.59277 1 6.06993 1 11.5928C1 17.1156 5.47715 21.5928 11 21.5928Z"
                    stroke="#53B648"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M7.5 12.0928L9.5 14.0928L14.5 9.09277"
                    stroke="#53B648"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="ms-3">
                Enjoy customizable rental periods to fit your schedule—whether
                you need a place for a month or longer.
              </span>
            </p>
            <p className="mt-2 flex mb-6">
              <div>
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 21.5928C16.5228 21.5928 21 17.1156 21 11.5928C21 6.06993 16.5228 1.59277 11 1.59277C5.47715 1.59277 1 6.06993 1 11.5928C1 17.1156 5.47715 21.5928 11 21.5928Z"
                    stroke="#53B648"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M7.5 12.0928L9.5 14.0928L14.5 9.09277"
                    stroke="#53B648"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="ms-3">
                Transparent pricing ensures you know exactly what you’re paying
                for.
              </span>
            </p>
            <span>
              <svg
                width="95"
                height="21"
                viewBox="0 0 95 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.77916 17.8235L6.11253 12.0593L1.64062 8.18226L7.54846 7.66943L9.84595 2.2334L12.1434 7.66943L18.0513 8.18226L13.5794 12.0593L14.9127 17.8235L9.84595 14.767L4.77916 17.8235Z"
                  fill="#EFD165"
                />
                <path
                  d="M23.3768 17.8235L24.7102 12.0593L20.2383 8.18226L26.1461 7.66943L28.4436 2.2334L30.7411 7.66943L36.6489 8.18226L32.177 12.0593L33.5104 17.8235L28.4436 14.767L23.3768 17.8235Z"
                  fill="#EFD165"
                />
                <path
                  d="M41.9764 17.8235L43.3098 12.0593L38.8379 8.18226L44.7457 7.66943L47.0432 2.2334L49.3407 7.66943L55.2485 8.18226L50.7766 12.0593L52.11 17.8235L47.0432 14.767L41.9764 17.8235Z"
                  fill="#EFD165"
                />
                <path
                  d="M60.576 17.8235L61.9094 12.0593L57.4375 8.18226L63.3453 7.66943L65.6428 2.2334L67.9403 7.66943L73.8482 8.18226L69.3763 12.0593L70.7096 17.8235L65.6428 14.767L60.576 17.8235Z"
                  fill="#EFD165"
                />
                <path
                  d="M79.1737 17.8235L80.5071 12.0593L76.0352 8.18226L81.943 7.66943L84.2405 2.2334L86.538 7.66943L92.4458 8.18226L87.9739 12.0593L89.3073 17.8235L84.2405 14.767L79.1737 17.8235Z"
                  fill="#EFD165"
                />
              </svg>
            </span>
            <p className="mt-3">
              I had a fantastic experience with GT Luxe for my place renting!
              The wide selection of places, competitive rates, and hassle-free
              booking made my stay easy and enjoyable. Highly recommend for
              anyone needing a reliable place for an extended period!
            </p>

            <p className="text-sm mt-3">
              <strong>Emily Roberts,</strong> <br />
              Traveller
            </p>
          </div>
          <div className="md:basis-1/2 basis-full">
            <img className="object-cover" src={sideImage} alt="hero" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacesRental;
