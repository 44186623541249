import clientSay1 from "images/clientSay1.jpg";
import clientSay2 from "images/clientSay2.jpg";
import clientSay3 from "images/clientSay3.jpg";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";

export const globalJson = {
  // apiUtil auth header key
  prod_company_id: 19,
  dev_company_id: 1,
  
  authToken: "Bearer 160|KtAVGCCFWyadGgkHgP241Vf5pDRxhVrnUlscpqoa",
  companyId: "8bd5c546-7c75-44cc-a85e-520acce5e25b",
  stripePublishableKey:
    "pk_live_51PG71eH2YI6MHKgbAq7AtNSviRaarDoebX07TJcsAgf583VhiE9bvAGrWY9P6ZOiobZ0ZBbSlxfwC1SwDfBRamVE00kMpPSEml",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc", // should be same
  residenceStateCode: "FL",

  //email confirmation admin token
  adminEmailToken:
    "YSQxQBJFUkxHkX3GRrQZM1wYFrIEzNCXkOEyEiIm1vpzI3gE45EaJaNOygydqkFotB1FQqFJihdYtUO9g7DdMnMQVReQ5j8Vv3nRTYg9vrbHj0UEEeOfk91YdZk73Pzd",
  websiteLink: "https://www.gt-luxe.com",
  sender: "gt-luxe",
  ccEmail: "gtluxuryrentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_m53m05e",
  util_templateId: "template_16vtnsz",
  util_userId: "fw4jLTyXRw53G3Zml",
  util_privateKey: "fxEoXpQIqohHQEqzjRBl2",

  // apiUtil getBonzahToken
  client_secret_dev:
    "CJMSQDyj6T53BjqcNur7QwrCqJF52WESGLqt6DJemixVDTihUXWzoPLl1jqa/j2IrOY72GyGG6QUSvnM9GnqtNuA1Wf/ghP0Z0AalBWo/S5Lw0sUiI1s/PIPgQql/Liv709I3w==",
  client_id_dev:
    "96cc71d8-4ec0-45b8-8924-96cce2cf02e0-20240525523480-vendor@bonzah.com",
  client_secret_prod:
    "Fhu2W6vN/LQc0R86mDtkZDubYwlFtr8u01GYOo7y0kIv7Wv2K3co8N2a8OS78r6fkxKBokM7GE7Mv9tTBzYxJGJ7g9Q63f6HqhqxEVDzwxZqJVBDAc4Il5x7CL7nJLDPcdFzdg==",
  client_id_prod:
    "b3999a17-6043-46b2-8958-7f3b6b2852b0-20240525331666-vendor@bonzah.com",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/gtluxerentals-5de60.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=755629da-264f-4f37-81b7-a5d5a47141f7",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyAHjhDNdHbEaLRO1Loze6Xoj6IRYPcvqK0",
    authDomain: "gtluxerentals-5de60.firebaseapp.com",
    projectId: "gtluxerentals-5de60",
    storageBucket: "gtluxerentals-5de60.appspot.com",
    messagingSenderId: "343093575806",
    appId: "1:343093575806:web:c5f3f5cab7a830d673182f",
    measurementId: "G-ZY5H0QW32W",
  },

  about_us_para:
    "Welcome to GT LUXE LLC, where we redefine your journey with a commitment to excellence and unparalleled service. As a premier rental car provider, we take pride in offering a seamless and exceptional experience for every traveler. We understand that each journey is unique, and we strive to be your trusted companion on the road. Our fleet of meticulously maintained vehicles ranges from sleek sedans to spacious SUVs, ensuring we have the perfect ride for every occasion.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Emma Z.",
      clientAddress: "Talahassee, Florida",
      content:
        "GT Luxe was extremely communicative and responsive to all our questions - Would definitely recommend!",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Andrew A.",
      clientAddress: "Talahassee, Florida",
      content:
        "Great renter. Took good care of the car and communicated well! ",
      img: clientSay2,
    },
    {
      id: 3,
      clientName: "Miguel R.",
      clientAddress: "Talahassee, Florida",
      content:
        "Car and communication was excellent. Great experience and would definitely use again.",
      img: clientSay3,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",

  //contact
  address: "1241 West Tharpe Tallahassee Florida, 32303",
  addressLink:
    "https://www.google.com/maps/place/1241+W+Tharpe+St,+Tallahassee,+FL+32303,+USA/@30.4628324,-84.3033497,17z/data=!3m1!4b1!4m6!3m5!1s0x88ecf5b048295473:0x5ca7ba6e7d35ded9!8m2!3d30.4626354!4d-84.30374!16s%2Fg%2F11bw3xpx30?entry=ttu",
  phone: "850-898-2703",
  email: "Solutions@gtluxe.co",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46533778.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
