import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";

import FleetCard from "components/FleetCard/FleetCard";

import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import { CarListingModal } from "models/vehicleModal";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const { ourVehicles, setSelectedCar } = useData();

  const [filteredFleet, setFilteredFleet] =
    useState<CarListingModal[]>(ourVehicles);
  const [maxPrice, setMaxPrice] = useState<any>(0);
  const [rangePrices, setRangePrices] = useState<any>([0, 0]);

  useEffect(() => {
    let maxiPrice = ourVehicles
      ? ourVehicles.reduce((acc, current) => Math.max(acc, current.price), 0)
      : 0;
    setMaxPrice(maxiPrice);
    setRangePrices([0, maxiPrice]);
  }, [ourVehicles]);

  useEffect(() => {
    if (
      selectedTypes.length == 0 ||
      Array.from(new Set(ourVehicles!.map((car) => car?.fleet_class))).filter(
        (item): item is any => item !== undefined
      ).length == selectedTypes.length
    ) {
      setFilteredFleet(ourVehicles);
    } else {
      setFilteredFleet(
        ourVehicles!.filter((item: CarListingModal) =>
          selectedTypes.includes(item?.fleet_class?.name ?? "")
        )
      );
    }
  }, [selectedTypes, ourVehicles]);

  useEffect(() => {
    setFilteredFleet(
      ourVehicles!.filter(
        (item: any) =>
          item.price >= rangePrices[0] && item.price <= rangePrices[1]
      )
    );
  }, [rangePrices]);

  const renderCard = (car: CarListingModal) => {
    return (
      <FleetCard
        key={car.id}
        data={car}
        simpleImg={true}
        onClick={() => {
          navigate("/listing-car");
          setSelectedCar(car);
        }}
      />
    );
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{ minHeight: "80vh" }}
    >
      <div className="flex flex-col sm:flex-row justify-between align-center">
        <h2 className="text-4xl font-semibold">Our Fleet</h2>
        <div className="mb-8 lg:mb-0 flex items-center">
          <TabFilters
            types={[
              ...Array.from(
                new Set(ourVehicles!.map((car) => car?.fleet_class?.name ?? ""))
              ).filter(
                (item): item is string => item !== undefined && item !== ""
              ),
            ]}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            rangePrices={rangePrices}
            setRangePrices={setRangePrices}
            maxPrice={maxPrice}
          />
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative mt-10">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.map((car) =>  car?.active === true && renderCard(car))}
        </div>
        <div className="flex mt-16 justify-center items-center">
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
