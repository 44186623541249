import { format } from "date-fns";
import { globalJson } from "global/global_json";
import CreateBookingModel from "models/CreateBookingModel";
import { CreateBookingResponseModel } from "models/CreateBookingResponseModel";
import { PurchaseInsurance } from "models/PurchaseInsurance";

const API_URL = "https://api.fleetwire.io/api/v2";
const BONZAH_API_URL =
  process.env.REACT_APP_ENV != "prod"
    ? "https://vendor-api-dev.azurewebsites.net"
    : "https://business-api.bonzah.com";
const AUTH_TOKEN = globalJson?.authToken;
const MAPS_KEY = globalJson?.mapKey;
const getHeaders = () => ({
  Accept: "application/json",
  Authorization: AUTH_TOKEN,
});

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || errorData.detail || "Server responded with an error"
    );
  }
  const data: unknown = await response.json();
  // Here you might want to add runtime type checks depending on what T could be
  return data as T; // This assumes that the calling code knows what type it expects.
}

// export const searchLocations = async (input: string) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${MAPS_KEY}`,
//     {
//       headers: getHeaders(),
//     }
//   );
//   return handleResponse(response);
// };

// export const getNearbyLocations = async (placeId: string) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${MAPS_KEY}`
//   );
//   return handleResponse(response);
// };

// export const getLocationFromLatLong = async (latLng: string) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&sensor=true&key=${MAPS_KEY}`
//   );
//   return handleResponse(response);
// };
export const searchLocations = async (input: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/google_maps_autocomplete/?input=${input}`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const getNearbyLocations = async (placeId: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/check_location_in_range/?place_id=${placeId}&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};

export const getLocationFromLatLong = async (latLng: string) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&sensor=true&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};

export const getCompanyData = async () => {
  const response = await fetch(
    `https://tenant.fleetwire.io/api/v1/company/${globalJson.companyId}?include=deliveryLocations,taxCategories`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const getCarsListing = async () => {
  const response = await fetch(`${API_URL}/listings`, {
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const getAvailableCarsListing = async (fromDate: Date, toDate: Date) => {
  function getFormattedTime(date: Date) {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  const response = await fetch(
    `${API_URL}/listings/availability?start=${format(
      fromDate,
      "yyyy-MM-dd"
    )}%20${getFormattedTime(fromDate)}&end=${format(
      toDate,
      "yyyy-MM-dd"
    )}%20${getFormattedTime(toDate)}`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const getCarDetails = async (carId: string) => {
  const response = await fetch(`${API_URL}/listings/${carId}`, {
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const getExtras = async () => {
  const response = await fetch(`${API_URL}/extras-v2`, {
    headers: getHeaders(),
  });

  return handleResponse(response);
};

export const getCarDailyPricing = async (
  carId: string,
  fromDate: Date,
  toDate: Date
) => {
  const response = await fetch(
    `${API_URL}/listings/${carId}/daily_pricing?start=${format(
      fromDate,
      "yyyy-MM-dd"
    )}&end=${format(toDate, "yyyy-MM-dd")}`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const checkCoupon = async (coupon: string, email: string) => {
  const response = await fetch(
    `https://${globalJson.companyId}.fleetwire.io/api/v1/company/${globalJson.companyId}/coupons/check?coupon=${coupon}&email=${email}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
  return handleResponse(response);
};

export const createBooking = async (createBookingModel: CreateBookingModel) => {
  const response = await fetch(`${API_URL}/checkout`, {
    method: "POST",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(createBookingModel),
  });

  return handleResponse(response);
};

export const emailBookingConfirmation = async (body: any) => {
  await fetch("https://api-dev.1now.app/api/users/send-email/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // token: globalJson?.adminEmailToken,
    },
    body: JSON.stringify(body),
  });
};

export const sendNewsLetterEmail = async (
  email: string,
  name: string,
  message: string
) => {
  const serviceId = globalJson?.util_serviceId;
  const templateId = globalJson?.util_templateId;
  const userId = globalJson?.util_userId;
  const privateKey = globalJson?.util_privateKey;

  await fetch("https://api.emailjs.com/api/v1.0/email/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Origin: "https://dashboard.emailjs.com",
    },
    body: JSON.stringify({
      service_id: serviceId,
      template_id: templateId,
      user_id: userId,
      accessToken: privateKey,
      template_params: { email, name, message },
    }),
  });
};

export const getCustomerBookings = async (email: string) => {
  const response = await fetch(`${API_URL}/bookings?email=${email}`, {
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const cancelBookingWithId = async (id: string, token: string) => {
  const response = await fetch(
    `https://tenant.fleetwire.io/api/v1/bookings/${id}/cancel`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        b_id: id,
        refund: 0,
        unblockTuro: true,
        sendCancellationEmail: true,
      }),
    }
  );
  return await handleResponse(response);
};

export const getAbandonBookings = async () => {
  const response = await fetch(`${API_URL}/zapier/abandoned-bookings`, {
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const sendEmail = async (token: string, bookingId: string) => {
  const response = await fetch(
    `${API_URL}/bookings/${bookingId}/resend-confirmation-email`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
    }
  );
  return handleResponse(response);
};

export const createFleetwireCustomer = async (
  name: string,
  email: string,
  token: string
) => {
  const response = await fetch(`https://tenant.fleetwire.io/api/v1/renters`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      loaded: true,
      full_name: name || email,
      email,
      payment_sources: [],
      creditCards: [],
      attributes: [],
      uuid: null,
    }),
  });
  return handleResponse(response);
};

export const getStripeVerificationDetails = async (document_vr_id: string) => {
  const response = await fetch(
    `https://api-dev.1now.app/api/users/stripe-verification-report/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        verification_id: document_vr_id,
        token: globalJson.adminEmailToken,
      }),
    }
  );
  return handleResponse(response);
};

export const getFleetwireCustomer = async (renterId: string, token: string) => {
  const response = await fetch(
    `https://tenant.fleetwire.io/api/v1/renters/${renterId}?include=stripe_verifications,insurance_status`,
    {
      headers: { Authorization: token },
    }
  );
  if (process.env.REACT_APP_ENV != "prod") {
    return {
      success: true,
      renter: {
        r_id: "r_2J7YPAA8",
        uuid: "7ce1eacb-2019-4061-8570-64a6ca1351c9",
        full_name: "Martha Cardoso",
        first_name: "Martha",
        last_name: "Cardoso",
        phone: "+17184275347",
        dob: "1994-12-10",
        email: "mcardoso1210@yahoo.com",
        photo_url:
          "https://www.gravatar.com/avatar/b67ddc95a280d5f0d4e68ef8fbecef7b?s=200&d=mm",
        stripe_id: "cus_NpBIcHKkD4fP0p",
        source: "direct",
        phone_verified: null,
        sms_subscribed: true,
        email_subscribed: null,
        email_verified_at: null,
        created_at: "2023-05-03T04:04:59.000000Z",
        identity_verified_at: "2023-05-03 04:16:15",
        identity_status: "verified",
        dob_source: "Stripe Identity",
        id_expiration: "2025-12-10",
        legal_type: "person",
        discount_percentage: null,
        deposit_type: null,
        deposit_value: null,
        insurance: [
          {
            ignition_uri:
              "https://ignition.axle.insure/?token=ign_Tp_WRQkYxJKydzBG6Jifq",
            policy_account_id: "acc_OvtI1thtjPXKOkD2UAJb7",
            policies: ["pol_WL5mNjbQuNhnFmV2waRDT"],
            status: null,
            results: {
              id: "pol_WL5mNjbQuNhnFmV2waRDT",
              account: "acc_OvtI1thtjPXKOkD2UAJb7",
              type: "auto",
              carrier: "geico",
              policyNumber: "6134001459",
              isActive: true,
              effectiveDate: "2023-06-30T00:00:00.000Z",
              expirationDate: "2023-12-30T00:00:00.000Z",
              address: {
                addressLine1: null,
                country: null,
                addressLine2: null,
                state: null,
                city: null,
                postalCode: null,
              },
              coverages: [
                {
                  limitPerPerson: 25000,
                  code: "BI",
                  label: "Bodily Injury",
                  limitPerAccident: 50000,
                },
                {
                  code: "PD",
                  label: "Property Damage",
                  limitPerAccident: 25000,
                },
                {
                  limitPerPerson: 25000,
                  code: "UMBI",
                  label: "Uninsured Motorists Bodily Injury",
                  limitPerAccident: 50000,
                },
                {
                  property: "prp_NbRHd7FG_G88Xj3zTNOLi",
                  code: "COMP",
                  label: "Comprehensive",
                  deductible: 500,
                },
                {
                  property: "prp_NbRHd7FG_G88Xj3zTNOLi",
                  code: "COLL",
                  label: "Collision",
                  deductible: 1000,
                },
              ],
              properties: [
                {
                  type: "vehicle",
                  data: {
                    vin: "1V2KE2CA3PC227880",
                    model: "ATLAS",
                    year: "2023",
                    make: "VOLKS",
                  },
                  id: "prp_NbRHd7FG_G88Xj3zTNOLi",
                },
              ],
              insureds: [
                {
                  firstName: "Jeffrey",
                  lastName: "Resto",
                  dateOfBirthYear: "1996",
                  licenseNo: null,
                  licenseState: null,
                  dateOfBirth: "1996-08-21T00:00:00.000Z",
                  type: "primary",
                },
              ],
              thirdParties: [
                {
                  name: "Vw Credit Inc",
                  property: "prp_NbRHd7FG_G88Xj3zTNOLi",
                  address: {
                    addressLine1: "Po Box 258",
                    country: null,
                    addressLine2: null,
                    state: "MN",
                    city: "Minneapolis",
                    postalCode: "55440-0258",
                  },
                  type: "lienholder",
                },
              ],
              documents: [],
              createdAt: "2023-08-24T16:27:54.582Z",
              modifiedAt: "2023-08-24T16:27:54.582Z",
              refreshedAt: "2023-08-24T16:27:54.582Z",
            },
            uuid: "2309fe36-f616-4f03-8811-7e2d5c2bcc96",
            created_at: "2023-08-24T16:26:03.000000Z",
            updated_at: "2023-08-24T16:27:59.000000Z",
          },
        ],
        orders: [],
        attributes: [],
        stripe_verifications: [
          {
            b_id: "b_TCFTXBZBSQ",
            customer_id: "r_2J7YPAA8",
            vs_id: "vs_1N3X09BEpPCSqzpfb4Npbzws",
            vip_id: null,
            redirect_to_url:
              "https://verify.stripe.com/start/live_YWNjdF8xTWFtY0dCRXBQQ1NxenBmLF9OcEJNYW9FWG9Ka0dmMmpaQVQycU1ybnVJZFExNW000100HWqYgA2g",
            status: "verified",
            document_vr_id: "vr_1N3X6vBEpPCSqzpfe8a2d4f4",
            document_status: "verified",
            selfie_vr_id: null,
            selfie_files: null,
            selfie_status: null,
            last_error: null,
            person_details: {
              document_number: "889050982",
              expiration_date: { day: 10, month: 12, year: 2025 },
              issued_date: { day: 15, month: 7, year: 2021 },
              issuing_country: "US",
              first_name: "MARTHA P",
              last_name: "CARDOSO",
              address: {
                city: "FLUSHING",
                country: "US",
                line1: "7261 150TH ST",
                line2: "APT 1D",
                postal_code: "11367",
                state: "NY",
              },
              dob: { day: 10, month: 12, year: 1994 },
              document_image:
                "https://files.stripe.com/links/MDB8YWNjdF8xTWFtY0dCRXBQQ1NxenBmfGZsX2xpdmVfWk5ZYjBLSDU5bkxQSzBWM3VkMUNEUkhj00NQGb74CL",
              selfie_image:
                "https://files.stripe.com/links/MDB8YWNjdF8xTWFtY0dCRXBQQ1NxenBmfGZsX2xpdmVfY1ZBVFBYMzlmMDluYm9tV1l5RFA4WXp100SZGNNnZ8",
            },
            created_at: "2023-05-03T04:09:13.000000Z",
            updated_at: "2023-05-03T04:16:15.000000Z",
          },
        ],
        is_custodian: false,
      },
    };
  } else {
    return handleResponse(response);
  }
};

export const getFleetwireRenters = async (email: string, token: string) => {
  const response = await fetch(
    `${API_URL}/renters?page=1&sortDesc=false&itemsPerPage=-1&includeBookings=false&search=${email}&include=stripe_identities,insurance`,
    {
      headers: { Authorization: token },
    }
  );
  return handleResponse(response);
};

export const startAxleIgnition = async (
  renterId: string,
  token: string,
  setAxleLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setAxleLoading(true);
  try {
    const response = await fetch(
      `https://tenant.fleetwire.io/api/v1/axle/start-ignition`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ r_id: renterId }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return handleResponse(response);
  } catch (error) {
    throw error;
  } finally {
    setAxleLoading(false);
  }
};

export const getBonzahToken = async () => {
  const response = await fetch(`${BONZAH_API_URL}/connect/token`, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      grant_type: "client_credentials",
      client_secret:
        process.env.REACT_APP_ENV != "prod"
          ? globalJson?.client_secret_dev
          : globalJson?.client_secret_prod,
      client_id:
        process.env.REACT_APP_ENV != "prod"
          ? globalJson?.client_id_dev
          : globalJson?.client_id_prod,
    }),
  });
  return handleResponse(response);
};

export const getBonzahQuote = async (fromDate: Date, toDate: Date) => {
  const bonzahApiToken: any = await getBonzahToken();
  const response = await fetch(`${BONZAH_API_URL}/api/policy/quote`, {
    method: "POST",
    headers: {
      accept: "application/octet-stream",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bonzahApiToken.access_token}`,
    },
    body: JSON.stringify({
      travelDetails: {
        destinationCountryCode: "USA",
        residenceCountryCode: "USA",
        residenceStateCode: globalJson?.residenceStateCode,
        departureDate: fromDate.toISOString(),
        returnDate: toDate.toISOString(),
        dropOffTime: "Earlier",
      },
    }),
  });
  return handleResponse(response);
};

export const purchaseInsurance = async (
  purchaseInsurance: PurchaseInsurance
) => {
  const bonzahApiToken: any = await getBonzahToken();
  const response = await fetch(
    "https://vendor-api-dev.azurewebsites.net/api/policy/purchase",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bonzahApiToken.access_token}`,
      },
      body: JSON.stringify(purchaseInsurance),
    }
  );
  return handleResponse(response);
};

export const stripeSession = async (renterId: string, token: string) => {
  const response = await fetch(
    "https://tenant.fleetwire.io/api/v1/stripe/identity",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ r_id: renterId }),
    }
  );
  return handleResponse(response);
};

// not being used for now
export const sendAgreement = async (bookingId: string, token: string) => {
  await fetch(`${API_URL}/envelope`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      b_id: bookingId,
      template_uuid: "b830681f-f6d0-4a5e-82c1-ba782f849aaf",
      send_method: { email: true, sms: false },
    }),
  });
};

// not being used for now
export const stripeSessionSecondaryDriver = async () => {
  const response = await fetch(
    "https://api.stripe.com/v1/identity/verification_sessions",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer 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",
      },
      body: new URLSearchParams({
        type: "document",
        "options[document][allowed_types][0]": "driving_license",
        "options[document][require_live_capture]": "true",
        "options[document][require_matching_selfie]": "true",
      }),
    }
  );
  return handleResponse(response);
};

// not being used for now

export const stripeVerificationSecondaryDriver = async (
  verificationSession: string,
  testing = false
) => {
  const response = await fetch(
    `https://api.stripe.com/v1/identity/verification_reports?verification_session=${verificationSession}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer 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",
      },
    }
  );

  return handleResponse(response);
};
