import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10  inset-x-0 top-1/4 sm:-translate-y-1/2 md:-translate-y-1/2 lg:translate-y-0 text-center flex flex-col items-center mx-auto space-y-3 lg:space-y-3 xl:space-y-5">
        <h2 className="font-bold text-secondary-900 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl  ">
          Unlock Your Journey
        </h2>
        <h2 className="font-bold text-primary-900 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl  ">
          Rent Your Perfect Ride
        </h2>
        <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
          <HeroSearchForm />
        </div>
        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover rounded-xl opacity-50"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
