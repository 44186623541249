import { Popover, Transition } from "@headlessui/react";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import { FC, Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Input from "shared/Input/Input";
export interface DatePickersProps {
  onChangeDate: (dates: [Date | null, Date | null])=>void;
  getDate: any;
}

const DatePickers: FC<DatePickersProps> = ({ getDate, onChangeDate }) => {
  return (
    <>
      <Popover
        className={`relative flex`}
      >
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 z-10 flex relative items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                <Input
                  className="mt-1 w-full"
                  readOnly
                  value={`${
                    getDate?.startDate
                      ? moment(getDate?.startDate).format("DD/MM/YYYY")
                      : "Pickup &"
                  }  ${
                    getDate?.endDate
                      ? `- ${moment(getDate?.endDate).format("DD/MM/YYYY")}`
                      : "Drop off Date"
                  }`}
                />
              </Popover.Button>
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                  <DatePicker
                    selected={getDate?.startDate}
                    onChange={onChangeDate}
                    startDate={getDate?.startDate}
                    endDate={getDate?.endDate}
                    selectsRange
                    monthsShown={2}
                    showPopperArrow={false}
                    inline
                    minDate={new Date()} // Disable all dates before today
                    renderCustomHeader={(p) => (
                      <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default DatePickers;
